import { ref } from 'vue';
import { featurePlan } from '@/data';
import { FeaturePlanData } from './all.type';

const initTableData = (projectType: ProjectType) => {
    const communityHeader = [{
        label: WordList.ProperAllTextFeaturePackageRoom,
        value: 0
    }, {
        label: WordList.ProperAllTextFeatureDisablePIN,
        value: 1
    }, {
        label: WordList.ProperAllTextFeatureDisableQR,
        value: 2
    }, {
        label: WordList.ProperAllTextFeatureFamilyMemberControl,
        value: 3
    }, {
        label: WordList.FaceRecognitionControl,
        value: 5
    }, {
        label: WordList.ThirdPartyCamera,
        value: 6
    }];
    const officeHeader = [{
        label: WordList.ProperAllTextFeaturePackageRoom,
        value: 0
    }, {
        label: WordList.ProperAllTextFeatureDisablePIN,
        value: 1
    }, {
        label: WordList.ProperAllTextFeatureDisableQR,
        value: 2
    }];
    let trHeader = communityHeader;
    // 办公不显示家庭相关
    if (projectType === 'office') {
        trHeader = officeHeader;
    }
    const featurePlanData = ref<Array<FeaturePlanData>>([{
        ID: '',
        Items: [],
        Name: '',
        FeaturePlanType: '',
        MonitorType: '',
        FeeData: {
            FeatureFee: 0,
            FeatureFeeType: '',
            FeatureName: '',
            FeaturePlanType: '',
            ID: '',
            UUID: ''
        }
    }]);

    const isShowLastRow = ref(false);

    const isShowLastRowFun = () => {
        featurePlanData.value.forEach((featurePlanItem: FeaturePlanData) => {
            if (featurePlanItem.MonitorType === 1) isShowLastRow.value = true;
        });
    };

    featurePlan.getDisFeaturePlan({ Type: projectType === 'office' ? '1' : '0' }, (res: Array<FeaturePlanData>) => {
        featurePlanData.value = res;
        isShowLastRowFun();
    });
    return {
        trHeader,
        featurePlanData,
        isShowLastRow
    };
};

export default initTableData;