
import {
    defineComponent,
    PropType
} from 'vue';
import initTableData from '@/components/view/installer/community/read-instructions-dialog/initListData';

export default defineComponent({
    props: {
        projectType: {
            type: String as PropType<ProjectType>,
            default: 'community'
        }
    },
    emits: ['close'],
    setup(props, { emit }) {
        const {
            trHeader, featurePlanData, isShowLastRow
        } = initTableData(props.projectType);
        return {
            emit,
            trHeader,
            featurePlanData,
            isShowLastRow
        };
    }
});

